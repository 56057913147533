.row_header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  grid-column-start: 1;
  background-color: rgb(123, 158, 171);
  border: 1px solid rgb(123, 158, 171);
  margin: 5px;
  margin-right: 10px;
  padding: 0px;
}

.row_header_selected {
  background-color: rgb(183, 193, 237);
  border: 1px solid black;
}

.row_header:hover {
  border: 1px solid black;
}

.row_header_title {
  flex-grow: 1;
}

.row_inserter {
  margin-bottom: 0px;
  margin-top: 5px;
  margin-right: 0px;
  padding: 0px;
  width: 100%;
  background-color: gray;
  overflow-y: hidden;
  color: white;
  font-weight: bold;
  text-align: middle;
  border-radius: 10px;
  font-size: 0.6rem;
}

.row_inserter:hover {
  background-color: black;
}