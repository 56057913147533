.item {
  margin-bottom: 5px;
  margin-top: 5px;
  height: 100px;
  width: 100px;
  background-color: rgb(225, 225, 225);
  border: 1px solid rgb(211, 211, 211);
  position: relative;
  font-size: 0.85rem;
  overflow-x: auto; 
  overflow-y: auto; 
}

.item_selected {
  border: 1px solid black;
  background-color: rgb(183, 193, 237);
}

.item:hover {
  border: 1px solid black;
}

.delete_button {
  position: absolute;
  right: 5px;
  top: 0px;
  padding: 0;
  margin: 0;
  font-weight: normal;
  line-height: 0.9rem;
}

.delete_button:hover {
  font-weight: bolder;
  color: red;
}