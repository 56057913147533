.column {
  display: flex;
  flex-direction: column;
}

.row_inserter {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 10px;
  padding: 0px;
  width: calc(100% - 10px);
  background-color: gray;
  overflow-y: hidden;
  color: white;
  font-weight: bold;
  text-align: middle;
  border-radius: 10px;
  font-size: 0.6rem;
}

.row_inserter:hover {
  background-color: black;
}