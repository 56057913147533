.board_scrollable_container {
  margin-right: 26vw;
  overflow: auto;
}

textarea {
  width: 90%; /*<<< TOTAL SHIT - nEED TO FIX */
  margin-left: 10px;
  margin-right: 10px;
}

.properties_panel {
  position: fixed;
  width: 25vw;
  height: 100vh;
  top: 0;
  right: 0;
  background: rgb(211,211,211);
  border-left: 1px solid gray;
}

.board_grid {
  display: grid;
  grid-auto-rows: min-content;
}

.grid_spacer {
  margin: 5px;
  height: 100px;
  width: 100px;
  display: hidden;
}

.column_inserter {
  margin: 5px;
  padding: 1px;
  background-color: gray;
  overflow-x: hidden;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  vertical-align: middle;
  text-align: middle;
  font-size: 0.6rem;
}

.column_inserter:hover {
  background-color: black;
}

.column_header {
  margin-bottom: 5px;
  margin-top: 5px;
  height: 100px;
  width: 100px;
  overflow-y: auto;
  background-color: rgb(186, 210, 220);
  border: 1px solid rgb(186, 210, 220);
  position:relative;
}

.column_header_selected {
  border: 1px solid black;
  background-color: rgb(183, 193, 237);
}

.column_header:hover {
  border: 1px solid black;
}


.delete_button {
  position: absolute;
  right: 5px;
  top: 0px;
  padding: 0;
  margin: 0;
  font-weight: normal;
  line-height: 0.9rem;
}

.delete_button:hover {
  font-weight: bolder;
  color: red;
}