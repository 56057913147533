.scrollable::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable::-webkit-scrollbar-thumb {
  background: #888;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background: #555;
}